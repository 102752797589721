import React from 'react';
import Helmet from 'react-helmet';
import { withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';
import { Link } from 'react-scroll';
import moment from 'moment';

function TermsAndConditions(props) {
  const { pageContext, data, location } = props;
  const lang = pageContext.lang;

  const scrollToTop = () => {
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
  }

  return (
    <div className="page page--terms-and-conditions page--light">
      <Helmet titleTemplate="%s - Interstellar Interactive">
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Interstellar Interactive" />
        <title>Terms And Conditions</title>
        <link
          rel="canonical"
          href={`https://www.interstellarinteractive.com${location.pathname}`}
        />
        <html lang={lang} />
        {/* <meta name="description" content={content.seo} /> */}
      </Helmet>
      <div className="page__content">
        <div className="container" name="back-to-top">
          <div className="page-title-section">
            <h1>Interstellar Interactive® - Terms & Conditions</h1>
            <h4 className="date-updated">Last updated: May 6, 2022</h4>
          </div>
          <div className="line"></div>
          <div className="navigation-list">
            <Link className="" to="copyright" smooth offset={-45}>Copyright</Link>
            <Link className="" to="notice-of-claimed-infringement" smooth offset={-45}>Notice Of Claimed Infringement</Link>
            <Link className="" to="trademarks" smooth offset={-45}>Trademarks</Link>
            <Link className="" to="required-age" smooth offset={-45}>Required Age</Link>
            <Link className="" to="submission-of-materials" smooth offset={-45}>Submission of Materials</Link>
            <Link className="" to="integrity-and-accuracy-of-the-information" smooth offset={-45}>Integrity and Accuracy of the Information</Link>
            <Link className="" to="non-liability" smooth offset={-45}>Non-liability</Link>
            <Link className="" to="indemnification" smooth offset={-45}>Indemnification</Link>
            <Link className="" to="hyperlinks-to-thirdparty-sites" smooth offset={-45}>Hyperlinks to Third-Party Sites</Link>
            <Link className="" to="hyperlinks-to-the-website" smooth offset={-45}>Hyperlinks to the Website</Link>
            <Link className="" to="accounts" smooth offset={-45}>Accounts</Link>
            <Link className="" to="acceptable-use-and-prohibitions" smooth offset={-45}>Acceptable Use and Prohibitions</Link>
            <Link className="" to="termination" smooth offset={-45}>Termination</Link>
            <Link className="" to="confidentiality" smooth offset={-45}>Confidentiality</Link>
          </div>
          <div className="line"></div>
          <div name="copyright" className="section-wrapper">
            <h2>1. Copyright</h2>
            <p>All articles, texts, illustration, images, photographs, information, audio clips, video clips, software and codes available on the Website (the “<b>Content</b>”), including their arrangement and compilation, are protected by Canadian and foreign laws, particularly regarding copyright, and belong to Interstellar Interactive, to its affiliated entities, to its licensors or to the person recognized as having provided the content.</p>

            <p>You undertake to respect all other copyright statements, notices or restrictions regarding or in the Content that you can access through the Website. Interstellar Interactive grants you a non-exclusive and non-transferable licence for use and display of the Website on your computer, or other electronic device, solely for the purposes of viewing it on a single screen and for copying a single copy of it for backup or printing on paper, for personal and non-commercial use, under the condition that you do not modify the content and that you retain any notices of copyright. Modification, reproduction, distribution, transmission, broadcast, representation, sharing on networks, marketing, publication, granting of licence, downloading, creation of derived works, display on the Internet, sale or any use of the Website or the Content, including, through storing on a proxy server, framing or similar means, are prohibited.</p>

            <p>All software integrated into the Website or found there, particularly but not limited to, any type of source code, and all files or images contained or generated by this software, are protected by copyright and may be protected by other rights. All such software belongs to Interstellar Interactive, to its affiliated entities or to its licensors.</p>

            <p>Unless clearly provided otherwise on the Website, no element in the Website shall be interpreted as conferring on you a right, a title, an interest or any other licence in the software integrated within the Website or that can be downloaded from the Website, including, specifically, any form of intellectual property right in the software.</p>
          </div>
          <div name="notice-of-claimed-infringement" className="section-wrapper">
            <h2>2. Notice Of Claimed Infringement</h2>

            <p>If you believe your work or content has been copied and posted to this Website in a way that constitutes copyright infringement, please provide Interstellar Interactive with the following written information in accordance with the Canadian <i>Copyright Act</i>:</p>

            <ul>
              <li>
                <p>A description of the copyrighted work that you claim has been infringed upon;</p>
              </li>
              <li>
                <p>A description of where the material that you claim is infringing is located on this Website;</p>
              </li>
              <li>
                <p>A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</p>
              </li>
              <li>
                <p>Your name, address, telephone number and email address (if available); and</p>
              </li>
              <li>
                <p>A statement by you that the above information in your notice is accurate and that you are the copyright owner or are authorized to act on the copyright owner’s behalf.</p>
              </li>
            </ul>
          </div>
          <div name="trademarks" className="section-wrapper">
            <h2>3. Trademarks</h2>
            <p>LIFECYCLE is a Interstellar Interactive trademark. Other names, words, titles, phrases, logos, drawings, graphics, icons and marks displayed on the Website may be Interstellar Interactive or third-party trademarks. No item on the Website may be interpreted in such a way as to grant anyone a licence or right to use any Interstellar Interactive or third party logo, drawing or mark.</p>
          </div>
          <div name="required-age" className="section-wrapper">
            <h2>4. Required Age</h2>
            <p>You acknowledge and agree that you are of legal age in the jurisdiction from which you are accessing the Website. If you are under the legal age, you are not permitted to access or use the Website, including accessing advertising or marketing information on the Website. No one under the legal age may access the Website. </p>

            <p>If you are of legal age, you acknowledge and agree that you will not forward this Website or share Content with underage persons. </p>

            <p>You must be of legal age to purchase any Interstellar Interactive products or to participate in any promotions and offers on the Website.</p>

            <p>Notwithstanding the foregoing, if you are located in Canada, the United States or Mexico, you are permitted to access and use the Website if you are 17 of age or older.</p>

          </div>
          <div name="submission-of-materials" className="section-wrapper">
            <h2>5. Submission of Materials</h2>
            <p>Unless specific regulations or written procedures by Interstellar Interactive exist in that regard, outlining other terms and conditions, when you submit messages, data, texts, photographs, images, receipts and other materials to Interstellar Interactive, you grant Interstellar Interactive a world, unlimited, irrevocable, non-exclusive, perpetual, fully paid-up licence for: i) use, reproduction, storage, adaptation, translation, modification, creation of derivative work, transmission, distribution or provision for public access to what you submitted for any purpose; and ii) granting sub-licences to third parties for the exercise of any of the aforementioned rights.</p>

            <p>In addition to the abovementioned licence, under these terms and conditions, you: i) waive all moral rights regarding your content, ii) consent that your name, address and email address appear regarding your content, where applicable, as well as the sharing or display of this information, iii) acknowledge and agree that Interstellar Interactive shall not be held responsible for any loss, damage or corruption to your content; and, iv) undertake and agree that the items that you supply for display on the Website or that you provide to Interstellar Interactive shall be considered as non-confidential.</p>

            <p>You represent and warrant to Interstellar Interactive that i) your content is original; ii) your content does not infringe any intellectual property rights, including without limitation copyright; iii) you are the sole owner of all intellectual property rights, including copyright without limitation, on and to your content or, where applicable, you have obtained written permission from the owner to provide your content; iv) your content does not infringe the privacy or image rights of a third party; v) your content is truthful, accurate, up to date and complete, to your knowledge; and vi) your content is not illegal in any other way.</p>

            <p>Interstellar Interactive has no obligation to verify content present on its Website or that is transmitted to it. The user recognizes, nevertheless, that Interstellar Interactive may at any point verify this content, particularly to comply with a law, a regulation, a decree or a request from the authorities, to ensure the correct functioning of the Website or for any other reason, at its discretion. Interstellar Interactive reserves the right to modify, refuse or delete any content present on its Website or that is transmitted to it if Interstellar Interactive considers, at its sole discretion, that this content contravenes these terms and conditions.</p>
          </div>
          <div name="integrity-and-accuracy-of-the-information" className="section-wrapper">
            <h2>6. Integrity and Accuracy of the Information</h2>

            <p>Interstellar Interactive shall endeavour to ensure that the information and Content available on the Website are complete, accurate and up to date. This information and Content comes from sources that Interstellar Interactive considers to be reliable, and are regularly updated. However, they may contain inaccuracies, omissions, spelling mistakes or other errors. These errors may relate to the prices, descriptions or availability of products. All images, text, and prices indicated on the Website are based on the most recent information at the time of being placed on the Website. Although Interstellar Interactive tries to ensure the product colours presented online are as accurate as possible, Interstellar Interactive cannot guarantee that the colour will be exactly that of the product.</p>

            <p>As a consequence, the Website and all of its Content are made available for users “as is”, without any warranty of any nature whatsoever, express or implied. Without limiting the foregoing, Interstellar Interactive makes no warranty regarding the availability or stability of the Website Content, the possibility to access it, the absence of any virus, or the precision, reliability, accuracy, integrity, exhaustive nature, performance or fitness for a particular purpose of the information provided, including that which relates to partners of Interstellar Interactive, to the legislation or regulation or availability of the services described. The user must take note that the Website is regularly subject to technical maintenance, which may limit or impede access for a temporary or prolonged period.</p>

            <p>The Website is accessible by way of the Internet and in standard Internet format. Interstellar Interactive reserves the right to modify these formats at any time and it is incumbent upon the user to obtain Internet access at his or her own cost, as well as any programs required to access the Website. Interstellar Interactive reserves the right to correct any error or change to the Content without prior notice. Interstellar Interactive has full discretion, above and beyond all other rights and recourses at its disposal, without liability, to, in any way, at any time and without prior notice, interrupt or restrict access to any element of its Website.</p>

            <p>Interstellar Interactive reserves the right to change the displayed prices at any time and without prior notice. Taxes and delivery fees are not included in the displayed prices. All prices displayed are in US dollars. Despite Interstellar Interactive's efforts to be as precise as possible, the price of a product appearing on the Web site may be inaccurate. If the product price advertised on the Web site is determined to be inaccurate, Interstellar Interactive reserves the right to either change the price or cancel a purchase made on its Website or otherwise, at its own discretion.</p>
          </div>
          <div name="non-liability" className="section-wrapper">
            <h2>7. Non-liability</h2>

            <p>Interstellar Interactive is providing this Website and its contents on an “as-is” basis and makes no representations or warranties of any kind, express or implied, with respect to the operation of this Website, the information, content, materials or products, included on this Website. To the fullest extent permitted by law, Interstellar Interactive disclaims all such representations and warranties, including for example warranties of merchantability and fitness for a particular purpose. In addition, Interstellar Interactive does not represent or warrant that the information accessible via this Website is accurate, complete or current. Price and availability information is subject to change without notice. Interstellar Interactive will not be liable for any damages of any kind arising out of or in connection with the use of this Website. Interstellar Interactive, their affiliated entities, and partners or licensors are in no way liable for direct or indirect damages, including but not limited to, a loss of revenue, customers or data, due to the use or incapacity to use or have access to the Website or the content and items these may allow access to. This limitation applies whether based on contractual or extra-contractual rights, even if Interstellar Interactive has been advised of the possibility of such damages. This is a comprehensive limitation of liability that applies to all damages of any kind, including but not limited to direct, indirect, incidental, punitive or consequential damages, loss of data, income or profit, loss of or damage to property and claims of third parties.</p>

            <p>Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for some damages. Accordingly, some of the above limitations may not apply to you.</p>
          </div>
          <div name="indemnification" className="section-wrapper">
            <h2>8. Indemnification</h2>

            <p>Upon Interstellar Interactive's request, you undertake to indemnify and keep harmless Interstellar Interactive, its affiliates, partners, subsidiaries or licensors as well as their employees, contractors, agents and directors, against any liabilities, claims and costs (including legal fees and costs) resulting from your use or errors in the use of the Website. To the fullest extent possible, you accept to cooperate with Interstellar Interactive for its defence in the context of any situation in which you should provide an indemnity.</p>
          </div>
          <div name="hyperlinks-to-thirdparty-sites" className="section-wrapper">
            <h2>9. Hyperlinks to Third-Party Sites</h2>

            <p>The Website may contain hyperlinks to third-party websites (“<b>Third-party Sites</b>”) that will navigate you away from the Website. Interstellar Interactive supplies these hyperlinks for your convenience. The Third-party Sites are not controlled by Interstellar Interactive and the hyperlinks cannot be interpreted as an approval or endorsement of their content by Interstellar Interactive. Interstellar Interactive is not responsible for the content of Third-party Sites, for links they contain, or for changes made or updates to these sites and Interstellar Interactive makes no warranty in that regard.</p>
          </div>
          <div name="hyperlinks-to-the-website" className="section-wrapper">
            <h2>10. Hyperlinks to the Website</h2>

            <p>Interstellar Interactive accepts hyperlinks to the Website. However, it does not want to be linked with any third-party website: i) that contains content constituting or encouraging a criminal or penal offence, would affect civil liability or would in any other way constitute an infringement of any municipal, provincial, national or international legislation or regulation, that could affect or harm the activities, credibility or integrity of Interstellar Interactive or that contains, displays or transmits any material or information that extends beyond the moral or legal standards of Canadian society; or ii) that contains, displays or transmits any information, software or other material that breaches or infringes the rights of others, including material that impede privacy rights or privacy protection rights, or that is protected by copyright, trademark or another property right. Interstellar Interactive reserves the right to prohibit or refuse to accept a hyperlink to the Website at its sole discretion, at any moment. You agree to remove any hyperlink that you may have to the Website upon the request of Interstellar Interactive.</p>
          </div>
          <div name="accounts" className="section-wrapper">
            <h2>12. Accounts</h2>
            
            <p>To register and create an account, you must complete the registration form. You will be provided at that moment with a user ID and a password. It is easy to change your account information at any time.</p>

            <p>In connection with your account (collectively referred to herein as an “Account”), you must adhere to the following:</p>

            <p>(a) Responsibility. You are solely responsible for</p>

            <p>(i) your Account and the maintenance, confidentiality and security of your Account and all passwords related to your Account; and</p>

            <p>(ii) any and all activities that occur under your Account, including all activities of any persons who gain access to your Account with or without your permission.</p>

            <p>(b) Notification. You agree to immediately notify Interstellar Interactive of</p>

            <p>(i) any unauthorized use of your Account, any service provided through your Account or any password related to your Account; or</p>

            <p>(ii) any other breach of security with respect to your Account or any service provided through it; and</p>

            <p>you agree to provide assistance to Interstellar Interactive, as requested, to stop or remedy any breach of security related to your Account. In no event will Interstellar Interactive have any liability for any loss or damages that may occur due to the unauthorized or authorized use of your Account or password, with or without your knowledge;</p>

            <p>(c) Accuracy. You agree to provide true, current, accurate and complete information as requested by Interstellar Interactive or its agents from time to time and you agree to promptly notify Interstellar Interactive of any changes to this information as required to keep such information held by Interstellar Interactive current, complete and accurate. You agree to not use any other person’s Account at any time, unless you have received direct written consent from the other Account holder. You agree to not share your user ID and password with anyone.</p>
          </div>
          <div name="acceptable-use-and-prohibitions" className="section-wrapper">
            <h2>13. Acceptable Use and Prohibitions</h2>

            <p>You will ensure that:</p>

            <p>(i) you only use the Website for lawful purposes, and</p>

            <p>(ii) if at any time you become aware of any violation, by any person or entity, you will immediately notify Interstellar Interactive and provide Interstellar Interactive with assistance, as requested, to stop or remedy such violation.</p>

            <p>Without limiting the generality of any other restriction in these terms and conditions, you agree that you will not, in connection with the Website, directly or directly, or permit anyone to post, upload, reproduce, distribute or otherwise transmit any content that:</p>

            <p>(i) constitutes unauthorized or unsolicited commercial communications, junk or bulk communications or other “spam” (whether or not using email services, including instant messaging, blog or comments) or is otherwise duplicative or unsolicited;</p>

            <p>(iii) contains a virus, cancelbots, corrupted files, Trojan horse, worm or other harmful, disruptive, malicious or surreptitious software or program that may damage the operation of another computer or property of another; or</p>

            <p>(iii) is defamatory, infringing, or unlawful.</p>
          </div>
          <div name="termination" className="section-wrapper">
            <h2>14. Termination</h2>

            <p>Interstellar Interactive reserves the right, at its sole discretion, to terminate your access to all or part of the Website, with or without prior notice, specifically in the case of a breach of these terms and conditions.</p>
          </div>
          <div name="confidentiality" className="section-wrapper">
            <h2>15. Confidentiality</h2>

            <p>Interstellar Interactive's Privacy Policy, the content of which is available on the Website, is considered to be an integral part of these terms and conditions.</p>

            <p>Interstellar Interactive &copy; {moment().format('Y')} LLC. ALL RIGHTS RESERVED.</p>
          </div>
          
          <Link className="scroll-to-top" to="back-to-top" smooth offset={-1000}>Back to top</Link>
        </div>
      </div>
    </div>
  );
}

export default withI18n()(TermsAndConditions);

